<div class="container_wrap" >
    <div class="casino_visual bg-back2 py-8">
        <div class="container">
            <app-swiper-notice />
        </div>
    </div>

    <div class="container casino_main">
        <div class="casino_search flex-1 mx-2 mt-5 relative">
            <svg class="absolute left-3 top-3 w-4 h-4 fill-place"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Search"></use></svg>
            <input type="text" name="casino_search" class="form-control form-control-rounded bg-back2 pl-9" placeholder="게임이름 | 게임사 | 카테고리 태그" (click)="onSearch()">
            <svg class="absolute right-3 top-4 w-3 h-3 fill-title close_btn cursor-pointer"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use></svg>
        </div>
    </div>

    <div class="py-3 md:py-10">
        <div class="container">
            <ul class="nav nav-boxed-tabs gap-2 overflow-x-auto scrollbar_x">
                <li class="nav-item" role="presentation"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'home'}" type="button" (click)="onTab('home')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Lobby"></use></svg> 
                    </button>
                </li>
                <li class="nav-item" > 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'live_casino'}" type="button" (click)="onTab('live_casino')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Slots"></use></svg> 라이브카지노
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'korean_dealer'}" type="button" (click)="onTab('korean_dealer')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Favorites"></use></svg> 코리안 딜러
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'speed_baccarat'}" type="button" (click)="onTab('speed_baccarat')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_FeatureBuy-in"></use></svg> 스피드 바카라
                    </button>
                </li>
                <li class="nav-item" > 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'tv_show_game'}" type="button" (click)="onTab('tv_show_game')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_PicksForYou"></use></svg> TV쇼 게임
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'vip_salon'}" type="button" (click)="onTab('vip_salon')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_HotGame"></use></svg> VIP 살롱
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'baccarat'}" type="button" (click)="onTab('baccarat')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_FeatureBuy-in"></use></svg> 바카라
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'holdem_poker'}" type="button" (click)="onTab('holdem_poker')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_LiveCasino"></use></svg> 홀덤&포커
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'roulette'}" type="button" (click)="onTab('roulette')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_NewReleases"></use></svg> 룰렛
                    </button>
                </li>
                <li class="nav-item"> 
                    <button class="btn btn-top type02 h-11 px-5 whitespace-nowrap" [ngClass]="{'active': tab == 'blackjack'}" type="button" (click)="onTab('blackjack')">
                        <svg class="w-5 h-5 mr-2 fill-basic "><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Blackjack"></use></svg> 블랙잭
                    </button>
                </li>
            </ul>
        </div>
    </div>
    
    <div class="tab-content container pb-10">
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'home'}"> 
            <ng-container *ngFor="let it of swiperItems; let i = index;">
                <div class="mb-11" [ngClass]="it.code">
                    <div class="flex items-center justify-between">
                        <p class="text-xl text-tit font-medium">{{ it.name }}</p>
                        <div>
                            <button type="button" (click)="onTab(it.code)" class="text-primary mr-2">모두보기</button>
                            <button type="button" class="btn btn-top type02 h-9 swiper-prev"><svg class="w-3 h-3 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                            <button type="button" class="btn btn-top type02 h-9 swiper-next"><svg class="w-3 h-3 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></button>
                        </div>
                    </div>
                    <div class="slide_box mt-2.5 casino_slide overflow-hidden" *ngIf="it.items.length > 0">
                        <swiper-container swiper init="false" [swiperOptions]="it.swiperOptions">
                            <swiper-slide class="cursor-pointer bg-list rounded" *ngFor="let item of it.items; let i = index;">
                                <a [routerLink]="'/casino/play/' + (item['game_idx'] || item['idx'])">
                                    <img [src]="item['image']" [alt]="item['image']" *ngIf="item['image']" imgx>
                                </a>
                                <div class="flex items-center justify-between px-2 py-3">
                                    <p class="font-medium truncate game_tit"><a [routerLink]="'/casino/play/' + (item['game_idx'] || item['idx'])">{{ item['name'] }}</a></p>
                                    <div class="casino_gameinfo" *ngIf="item['notice']">
                                        <svg class="w-5 h-5 fill-basic cursor-pointer"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                                        <div class="hover_box p-2 bg-back2">
                                            <div class="flex items-center justify-between bg-modaldark p-1">
                                                <span>{{item['notice']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
            </ng-container>
            <!-- 최신 베팅&레이스 -->
            <div>
                <app-contest />
            </div>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'live_casino'}"> 
            <ng-template [ngIf]="tab == 'live_casino'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'korean_dealer'}"> 
            <ng-template [ngIf]="tab == 'korean_dealer'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'speed_baccarat'}"> 
            <ng-template [ngIf]="tab == 'speed_baccarat'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'tv_show_game'}"> 
            <ng-template [ngIf]="tab == 'tv_show_game'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'vip_salon'}"> 
            <ng-template [ngIf]="tab == 'vip_salon'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'baccarat'}"> 
            <ng-template [ngIf]="tab == 'baccarat'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'holdem_poker'}"> 
            <ng-template [ngIf]="tab == 'holdem_poker'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'roulette'}"> 
            <ng-template [ngIf]="tab == 'roulette'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
        <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'blackjack'}"> 
            <ng-template [ngIf]="tab == 'blackjack'">
                <app-casino-list [tag]="tab" />
            </ng-template>
        </div>
    </div>
</div>
